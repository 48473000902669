import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Stack from 'react-bootstrap/Stack'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import HistoryTab from './HistoryTab'
import BooksTab from './BooksTab'
import AuthorsTab from './AuthorsTab'
import LibbyTimelineTab from './LibbyTimelineTab'
import { AuthMenu, LoginPage } from '@naiveroboticist/legacy-auth-web'

const HELP_BASE_URL = process.env.REACT_APP_HELP_BASE_URL

function App() {

  const [alertText, setAlertText] = useState('')
  const [authorsTabOpen, setAuthorsTabOpen] = useState(false)
  const [booksTabOpen, setBooksTabOpen] = useState(false)
  const [libbyTabOpen, setLibbyTabOpen] = useState(false)
  const [historyTabOpen, setHistoryTabOpen] = useState(true)
  const [sessionInfo, setSessionInfo] = useState(null)
  const [showAlert, setShowAlert] = useState({ show: false, variant: 'primary' })

  const handleTabSelection = (eventKey, evt) => {
    setHistoryTabOpen(eventKey === 'history')
    setBooksTabOpen(eventKey === 'books')
    setAuthorsTabOpen(eventKey === 'authors')
    setLibbyTabOpen(eventKey === 'libby' )
  }

  const handleLoginModalClose = (loginResponse) => {
    if (loginResponse) {
      if (loginResponse.valid) {
        setSessionInfo(loginResponse.valid ? loginResponse.session_info : null)
        console.log('logged in')
        displayAlert(loginResponse.message, loginResponse.valid)
      } else {
        displayAlert('Invalid credentials', false)
        console.log('Invalid credentials')
      }
    }
  }

  const handleLogoutClick = () => {
    setSessionInfo(null)
  }

  const handleCloseCreateRegistrationModal = (regResponse) => {
    if (regResponse) {
      displayAlert(regResponse.message, regResponse.valid)
      if (regResponse.valid) {
        setTimeout(() => displayAlert(`The registration key: ${regResponse.registration_info.key}.`, true), 4000)
      }
    }
  }

  const handleCloseCompleteRegistrationModal = (cmpResponse) => {
    if (cmpResponse) {
      displayAlert(cmpResponse.message, cmpResponse.valid)
    }
  }

  const displayAlert = (message, valid) => {
    setAlertText(message)
    setShowAlert({ show: true, variant: valid ? 'primary' : 'danger' })
    setTimeout(() => setShowAlert({ show: false, variant: 'primary' }), 2000)
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Stack direction='horizontal' gap={3}>
            <Image src='book-shelf-svgrepo-com.svg' fluid style={{width: '90px', height: '90px'}}/>
            <h1>Reading History</h1>
            {sessionInfo && (
              <AuthMenu 
                sessionInfo={sessionInfo} 
                onLogout={handleLogoutClick} 
                onCloseCreateRegistration={handleCloseCreateRegistrationModal} />
            )}
          </Stack>
        </Col>
      </Row>
      {!!sessionInfo && (
        <Row>
          <Col>
            <Tabs defaultActiveKey={'history'} onSelect={handleTabSelection}>
              <Tab eventKey='history' title='History'>
                <HistoryTab
                  open={historyTabOpen}
                  helpBaseUrl={HELP_BASE_URL}
                  sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='books' title='Books'>
                <BooksTab
                  open={booksTabOpen} 
                  helpBaseUrl={HELP_BASE_URL}
                  sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='authors' title='Authors'>
                <AuthorsTab
                  open={authorsTabOpen} 
                  helpBaseUrl={HELP_BASE_URL}
                  sessionInfo={sessionInfo} />
              </Tab>
              <Tab eventKey='libby' title='Libby'>
                <LibbyTimelineTab
                  open={libbyTabOpen} 
                  helpBaseUrl={HELP_BASE_URL}
                  sessionInfo={sessionInfo} />
              </Tab>
            </Tabs>
  
          </Col>
        </Row>
      )}
      {!sessionInfo && (
        <>
        <Row>
          <Col />
          <Col>
            <LoginPage 
              onLoginClose={handleLoginModalClose} 
              onCloseCompleteRegistration={handleCloseCompleteRegistrationModal} 
            />
          </Col>
          <Col />
        </Row>
        </>
      )}
      <Row>
        <Col>
          <Alert variant={showAlert.variant} show={showAlert.show}>{alertText}</Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default App;